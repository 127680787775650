import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { 
    BACK_OFFICE_OPERATIONS, 
    getRequestToEdit,
    isValidated,
    getFeedbackToBackOfficeUser,
    VALIDATED_STATUS,
    PENDING_STATUS,
    REFUSED_STATUS,
    GO_BACK_ADVISOR_STATUS,
    advisorStatusList,
    supervisorStatusList
} from 'utils/domain/request'

import * as CatalogApi from 'services/api/CatalogApi'
import { useRequestFolder } from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/context'
import { useAlert } from 'hooks/useAlert'
import { privateRoutes } from 'router/routes'
import { equipmentList } from 'components/catalog/Equipment/list'
import { jsonHash } from 'utils/hash'
import { useAuth } from 'hooks/useAuth'

import Card from 'components/platform/Card'
import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import FormElement from 'components/shared/FormElement'
import Comment from 'components/shared/Comment'
import { scrollToTop } from 'utils/scrollTo'
import Picture from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/Pictures/Picture'
import { isNotEmpty } from 'utils/validation'
import { AdditionalEquipment } from './AdditionalEquipment'
import { BonusCalculation } from './BonusCalculation'

const getEquipmentsWithAQuantity = (equipments = {}) => 
        Object.fromEntries(Object.entries(equipments).filter(([, quantity]) => quantity !== '0'))

const defaultFormFields = {
    row_hash: '',
    comment: '',
    status: null
}

const EquipmentBid = ({ isBlockDisabled }) => {
    const [disabledActions, setDisabledActions] = useState(false)
    const [isChangingStatus, setIsChangingStatus] = useState(false)
    const [equipmentsFormFields, setEquipmentsFormFields] = useState({})
    const [ disabledButton, setDisabledButton] = useState(false)
    
    const [formFields, setFormFields] = useState(defaultFormFields)
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const [isBonusShown, setIsBonusShown] = React.useState(false)

    const { request, beneficiary, fetchRequest } = useRequestFolder()
    const { isABackOfficeManagementSupervisor } = useAuth()
    const { addAlert } = useAlert()
    const history = useHistory()

    const [
        firstPicture,
        secondPicture,
        thirdPicture,
        fourthPicture
    ] = request?.pictures

    const getEquipmentQuantity = (id) => request?.equipmentServicesInformations?.request_description?.[id] || request?.request_description?.[id]

    const getKeyObjectFromEquipmentList = () =>
        equipmentList.reduce((acc, { id }) => ({ ...acc, [id]: getEquipmentQuantity(id) || '0' }), {})

    React.useEffect(() => {
        setFormFields({
            amount_to_refund: request?.equipmentServicesInformations?.amount_to_refund,
            nbOfBikesPlaces: request?.equipmentServicesInformations?.nbOfBikesPlaces,
            comment: request?.request_data?.operation_data?.comment,
            row_hash: request?.row_hash
        })

        const equipments = getKeyObjectFromEquipmentList()
        setEquipmentsFormFields(equipments)
    }, [request]) // eslint-disable-line react-hooks/exhaustive-deps

    const commentHasChanged = request?.request_data?.operation_data?.comment && formFields?.comment && jsonHash(formFields.comment) === jsonHash(request.request_data.operation_data.comment)
    const isSendingFub = request.request_data?.operation_data?.sending_fub ?? false

    const isFormReadyForValidation = () => {
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return false
        }
        return true
    }

    const isFormReadyForAskMoreInformation = () => {
        let formReady = false

        if(errors.length > 0) {
            formReady = errors.map(error => {
                if(error.origin === 'amount_to_refund' || error.origin === 'nbOfBikesPlaces') {
                    if(error.rule === 'input-empty') formReady = true // this fields are not requiered for ask more information action
                }
                return formReady
            }) 
        }

        if (!formReady) {
            setShowLocalErrors(true)
            return false
        }
        return true
    }

    const declineRequest = async () => {
        try {
            //if(!isFormReadyForValidation()) return
            setDisabledActions(true)
            const extraData = { comment: formFields.comment }
            const { getError } = await CatalogApi.editBackOfficeRequest(
                getRequestToEdit(request, BACK_OFFICE_OPERATIONS.PICTURES.DECLINE, extraData)
            )
            if (getError()) throw getError()

            addAlert('success', 'Le dossier a bien été refusé')
            history.push(privateRoutes.backOffice.backOfficeHome.path)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        setFormFields(s => ({
            ...s,
            [name]: target.value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleChangeRequestStatus = async (event) => {
        event.preventDefault()
        setDisabledButton(true)

        if(formFields.status === VALIDATED_STATUS) {
            // On valide la demande => "validé"
            validateRequest()
            //setDisabledButton(false)
        } else if(formFields.status === PENDING_STATUS) {
            // On demande des infos supplémentaires au bénéficiaire => "en attente"
            try {
                const { getError } = await CatalogApi.editBackOfficeRequest(
                    getRequestToEdit(request, BACK_OFFICE_OPERATIONS.BID.ASK_MORE_INFORMATIONS, getExtraDataToSend())
                )
                if (getError()) throw getError()
                addAlert('success', 'Le dossier a bien été passé en "En attente".')
                fetchRequest()
                setIsChangingStatus(false)
            } catch(error) {
                addAlert('error', error?.toString())
            } finally {
                setDisabledButton(false)
            }
        } else if(formFields.status === REFUSED_STATUS) {
            // On refuse la demande => "refusé"
            declineRequest()
            setDisabledButton(false)
        } else if(formFields.status === GO_BACK_ADVISOR_STATUS) {
            // On est superviseur - admin bo gestion et on passe la request en "Retour conseiller"
            await handleRemoveSendingFub()
            setDisabledButton(false)
        } else {
            // Statut "transmis_fub" => on passe le boolean à true. 
            try {
                const updatedSendingFub = {
                    'last_row_hash': formFields.row_hash,
                    'request_id': request.request_id,
                    'sending_fub': true
                }
                    
                const { getError } = await CatalogApi.editBackOfficeEntityComment(updatedSendingFub)
                if (getError()) throw getError()
                addAlert('success', 'Le dossier a bien été passé en "Transmis FUB".')
                fetchRequest()
                setIsChangingStatus(false)
            } catch(error) {
                addAlert('error', error?.toString())
            } finally {
                setDisabledButton(false)    
            }
        }
    }

    const handleRemoveSendingFub = async () => {
        try {
            const updatedSendingFub = {
                'last_row_hash': request?.row_hash,
                'request_id': request.request_id,
                'sending_fub': false
            }
                
            const { getError: getCommentError } = await CatalogApi.editBackOfficeEntityComment(updatedSendingFub)
            if (getCommentError()) throw getCommentError()
            addAlert('success', 'Le dossier a bien été retourné au conseiller.')
            fetchRequest()
            setIsChangingStatus(false)
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const getExtraDataToSend = () => {
        return {
            amount_to_refund: Number(formFields.amount_to_refund),
            nbOfBikesPlaces: Number(formFields.nbOfBikesPlaces),
            request_description: getEquipmentsWithAQuantity(equipmentsFormFields),
            comment: formFields.comment,
        }
    }

    const validateRequest = async () => {
        try {
            if(!isFormReadyForValidation) return
            setDisabledActions(true)
            const { res, getError } = await CatalogApi.editBackOfficeRequest(
                    getRequestToEdit(request, BACK_OFFICE_OPERATIONS.BID.VALIDATE, getExtraDataToSend()
                )
            )
            if (getError()) throw getError()
            addAlert('success', 'Dossier équipement validé.')
            // DOLIBARR ERROR
            if(res?.erp_response?.error) addAlert('error', "Une erreur a été remontée sur l'interface Dolibarr. Veuillez vous connecter à Dolibarr et faire la modification.")

            history.push(privateRoutes.backOffice.backOfficeHome.path)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const saveComment = async () => {
        try {
            setDisabledActions(true)

            const updatedComment = {
                'last_row_hash': formFields.row_hash,
                'request_id': request.request_id,
                'comment': formFields.comment
            }
                
            const { res, getError } = await CatalogApi.editBackOfficeEntityComment(updatedComment)
            if (getError()) throw getError()

            addAlert('success', 'Le commentaire a bien été ajouté')

            if (res) {
                setFormFields(s => ({
                    ...s,
                    row_hash: res?.row_hash,
                    comment: res?.comment
                }))
            }
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const askMoreInformations = async (event) => {
        if(!isFormReadyForAskMoreInformation()) return
        
        try {
            setDisabledActions(true)
            const subject = `OEPV: Demande d'infos complémentaires pour le dossier n°${request?.request_fub_id}`
            window.location = `mailto:${beneficiary?.contactEmail}?subject=${subject}`
            event.preventDefault()
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    return (
        <div className={`${isBlockDisabled ? 'is-disabled-block' : ''}`}>
            { showGlobalError && <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur qui empêche la validation du formulaire.</p>}
            <Card 
                className="u-mg-top-m" 
                title="1. Contrôler les pièces justificatives"
                status={getFeedbackToBackOfficeUser(request?.status)}
            >
                <div className="u-flex u-flex-dir-row u-mg-bottom-l">
                    <div className='u-width-half'>
                        <InformationToVisualize 
                            label="Facture" 
                            url={request?.bill?.full_media_url} 
                        />
                        <InformationToVisualize 
                            label="Preuve de paiement" 
                            url={request?.payment_proof?.full_media_url} 
                        />
                    </div>

                </div>

                <div className="u-mg-bottom-s u-blue u-fs-m u-bold">2. Renseigner les lignes de facture</div>
                <AdditionalEquipment />

                <hr />

                <div className='u-mg-top-l u-mg-bottom-m u-blue u-fs-m u-bold'>3. Calculer le montant de la prime</div>
                    <h3 className='u-mg-bottom-s u-pd-left-s u-yellow u-fs-s u-bold'>Photos des travaux</h3>
                    <div className="u-flex u-flex-dir-row">
                        <Picture picture={firstPicture} alt="" />
                        <Picture picture={secondPicture} alt="" />
                        <Picture picture={thirdPicture} alt="" />
                        <Picture picture={fourthPicture} alt="" />
                    </div>


                <form className='u-mg-top-l'>
                    <div className='u-flex u-flex-between u-flex-center-vt'>
                        <div className="u-pd-bottom-m u-max-w-60-percent u-flex">
                            <FormElement
                                value={formFields.nbOfBikesPlaces}
                                name="nbOfBikesPlaces"
                                type="number"
                                label="Nombre de places vélo créées"
                                className=""
                                maxLength={5}
                                required
                                showErrors={showLocalErrors}
                                onValidate={handleValidation}
                                onChange={handleInputChange}
                                disabled={isValidated(request?.status)}
                            />
                             <button
                                type="button"
                                disabled={!isNotEmpty(formFields.nbOfBikesPlaces)} 
                                onClick={() => setIsBonusShown(true)}
                                className="c-btn c-btn--s c-btn--primary u-mg-m u-pd-s"
                            >Calculer le montant de la prime</button>
                        </div>
                    
                        {isBonusShown && <BonusCalculation nbOfBikesPlaces={formFields.nbOfBikesPlaces} setFormFields={setFormFields} />}
                    </div>

                    <div className='u-mg-top-l u-blue u-fs-m u-bold'>4. Valider la prime</div>
                    <Comment
                        value={formFields.comment}
                        showLocalErrors={showLocalErrors}
                        handleValidation={handleValidation}
                        handleInputChange={handleInputChange}
                        placeholder={'Ajouter un commentaire à votre dossier'}
                        maxLength={1000}
                    />
                    <button
                        type="button"
                        className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-pd-s"
                        onClick={saveComment}
                        disabled={commentHasChanged || disabledActions}
                    >
                        Enregistrer le commentaire
                    </button>

                    {!isValidated(request?.status) && (
                        <>
                        {isChangingStatus &&
                            <div className="u-flex u-flex-center-hz u-flex-end-vt u-mg-top-m">
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--danger c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                    onClick={() => {
                                        setIsChangingStatus(false)
                                    }}
                                >
                                    Annuler
                                </button>

                                {/* List of advisors */}
                                <FormElement
                                    value={formFields.status}
                                    options={isABackOfficeManagementSupervisor ? supervisorStatusList(request) : advisorStatusList(request)}
                                    name="status"
                                    type="select"
                                    label="Changer le statut"
                                    className="u-pd-hz-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                                
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                    onClick={handleChangeRequestStatus}
                                    disabled={
                                        !isNotEmpty(formFields.status) || 
                                        (formFields.status === VALIDATED_STATUS && !isBonusShown) || disabledButton
                                    }
                                >
                                    Valider
                                </button>
                            </div>
                        }
                        {!isChangingStatus &&
                            <div className="u-flex u-flex-dir-row u-flex-center-hz u-mg-top-m">
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                    onClick={() => setIsChangingStatus(true)}
                                    disabled={disabledActions}
                                >
                                    Changer le statut
                                </button>
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                    onClick={askMoreInformations}
                                    disabled={disabledActions}
                                >
                                    Demander des informations complémentaires
                                </button>
                            </div>
                            }
                        </>
                    )}
                     {formFields.status === VALIDATED_STATUS && !isBonusShown && <p className='u-center u-danger u-fs-xs u-pd-top-m'>Le calcul du montant de la prime est nécessaire pour valider le dossier</p>}
                </form>
            </Card>
        </div>
    )
}

export default EquipmentBid