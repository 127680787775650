import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { useModal } from 'hooks/useModal'
import { useAlert } from 'hooks/useAlert'

import ModalConfirmation from 'components/shared/ModalConfirmation'
import ModalInfo from 'components/shared/ModalInfo'

import { getMyScopingMeeting, planScopingMeeting } from 'services/api/scoping-meeting/ScopingMeetingApi'
import { hasAScopingMeeting } from 'utils/domain/scopingMeetings'
import { isNotEmpty } from 'utils/validation'

import dateService from 'services/date'

import { privateRoutes } from 'router/routes'
import './styles.scss'

const BACK_TO_HOME_PAGE = 'Retour à l\'accueil'

const MODAL_REQUEST_SENT_TITLE = 'Votre demande a été envoyée au prestataire'
const MODAL_REQUEST_SENT_BODY = `L’intervenant va rapidement vous contacter pour vous programmer
un rendez-vous pour votre réunion de cadrage ! Vous pouvez d’ores et déjà suivre votre réunion
dans l’onglet « Suivi de mes activités » de votre espace personnel. `
const MODAL_REQUEST_SENT_CONFIRM_LABEL = 'Suivre ma réunion de cadrage'
const MODAL_REQUEST_SENT_CANCEL_LABEL = BACK_TO_HOME_PAGE


const MODAL_STRUCTURE_NOT_CHECKED_TITLE = 'Votre établissement n’a pas été vérifié'
const MODAL_STRUCTURE_NOT_CHECKED_BODY = `Vous pourrez planifier votre réunion de cadrage
lorsque votre KBIS, SIRET ou SIRENE aura été validé par nos équipes.`
const MODAL_STRUCTURE_NOT_CHECKED_CONFIRM_LABEL = BACK_TO_HOME_PAGE

const ScopingMeetingPlanning = () => {
    const { user } = useAuth()
    const history = useHistory()
    const { showModal, hideModal } = useModal()
    const { addAlert } = useAlert()
    const [ isPlanningAScopingMeeting, setIsPlanningAScopingMeeting ] = useState(false)
    const [ hasPlannedAScopingMeeting, setHasPlannedAScopingMeeting ] = useState({})
    const [endedDate, setEndedDate] = React.useState(null)
    const [isScopingMettingActivated, setIsScopingMettingActivated] = React.useState(false)

    const isScopingMeetingEnded = endedDate && Date.now() > new Date(endedDate).getTime() + 86400000

    const fetchUpdatedDates = async () => {
        // Fetch distant document
        try {
            const response = await fetch(process.env.REACT_APP_UPDATED_DATES_URL)
            if (!response.ok) throw new Error('Failed to fetch updated dates')
            const data = await response.json()
            if(isNotEmpty(data)) setEndedDate(data?.dates?.['framing-meeting-end-date'])
            setIsScopingMettingActivated(user.entity_data.isFramingMeetingActivated)
        } catch (error) {
            console.log(error)
        }
    }

    const goToScopingMeetingMonitoringPage = () => {
        history.push(privateRoutes.monitoringActivities.scopingMeeting.path)
        hideModal()
    }

    const fetchMyScopingMeeting = async () => {
        try {
            const { res: scopingMeeting, getError } = await getMyScopingMeeting()
            if (getError()) throw getError()

            setHasPlannedAScopingMeeting(hasAScopingMeeting(scopingMeeting))
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    useEffect(() => {
        fetchMyScopingMeeting()
        fetchUpdatedDates()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const goToHomePage = () => {
        history.push(privateRoutes.home.path)
    }

    const showConfirmationModal = () => {
        showModal(
            ModalConfirmation,
            {
                title: MODAL_REQUEST_SENT_TITLE,
                body: MODAL_REQUEST_SENT_BODY,
                confirmLabel: MODAL_REQUEST_SENT_CONFIRM_LABEL,
                cancelLabel: MODAL_REQUEST_SENT_CANCEL_LABEL,
                validate: goToScopingMeetingMonitoringPage,
                cancel: goToHomePage,
                closeAfterValidation: true
            }
        )
    }

    const showStructureNotCheckedModal = () => {
        showModal(
            ModalInfo,
            {
                title: MODAL_STRUCTURE_NOT_CHECKED_TITLE,
                body: MODAL_STRUCTURE_NOT_CHECKED_BODY,
                actionLabel: MODAL_STRUCTURE_NOT_CHECKED_CONFIRM_LABEL,
                action: goToHomePage
            }
        )
    }
    
    const isKbisOrSiretValidated = !!user.entity_check?.is_check

    const handlePlanAScopingMeeting = async () => {
        setIsPlanningAScopingMeeting(true)
        try {
            if(!isKbisOrSiretValidated) {
                showStructureNotCheckedModal()
                setIsPlanningAScopingMeeting(false)
                return
            }

            const { getError } = await planScopingMeeting()
            if (getError()) throw getError()
            setIsPlanningAScopingMeeting(false)
           showConfirmationModal()
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
            setIsPlanningAScopingMeeting(false)
        }
    }

    return (
        <div className="l-container scoping-meeting-planning-page">
            <h1 className="c-h1 u-secondary u-bold u-center u-mg-top-l u-mg-bottom-l">
                Réunion de cadrage
            </h1>
            <div className="u-mg-top-xl">
                <div className="u-pd-m">
                    <p className="u-primary u-bold u-mg-bottom-l">
                        Cette réunion est une étape importante du Programme « Objectif Employeur Pro-Vélo » et a pour principal objectif de vous guider vers la labellisation ! 
                    </p>
                    <div className="u-black u-mg-bottom-m">        
                        La réunion de cadrage vise à :
                        <ul>
                            <li>Présenter le parcours d’accompagnement et le fonctionnement du programme OPEV (modalités du programme, primes, catalogue de prestations,...)</li>
                            <li>Débriefer sur l'autodiagnostic au regard des exigences du cahier des charges employeur pro-vélo, en pointant les exigences nécessitant une attention ou une action corrective</li>
                            <li>Proposer les prestations du catalogue (équipements, services) permettant de répondre à vos besoins et de vous faire progresser dans le cahier des charges</li>
                            <li>Offrir un temps de questions / réponses sur le Programme</li>
                        </ul>
                    </div>
                </div>
                <div className="u-pd-hz-xl u-pd-vt-l in-pratice-panel">
                    <h2 className="c-h4 u-secondary u-mg-bottom-m">En pratique</h2>
                    <p className="u-black u-mg-bottom-m">
                        La réunion est <strong>réalisée à distance</strong> avec les moyens du bénéficiaire, téléphone ou visio-conférence (à convenir avec le conseiller mobilité).
                    </p>
                    <div className="u-black u-mg-bottom-m">
                        Elle est menée par un <strong>conseiller mobilité</strong> prestataire sélectionné par le Programme.
                        De votre côté, il est indispensable pour le Programme <strong>qu’un interlocuteur ayant la délégation de prendre des décisions relatives à la politique vélo</strong> (si possible la personne référente vélo) de votre établissement participe à cette réunion de cadrage. Toute autre personne concernée par la politique mobilité du site employeur peut participer.
                    </div>
                    <p className="u-black u-mg-bottom-m">
                        La réunion est d’une durée maximale d’<strong>1h30.</strong> Elle est entièrement prise en charge par le programme et est donc <strong>gratuite pour l’employeur</strong> bénéficiaire.
                    </p>
                </div>
            </div>

            {(isNotEmpty(endedDate)) &&
                <div className="u-flex u-flex-center-hz u-flex-center-vt u-mg-top-l u-bg-danger u-white u-pd-s u-fs-s">
                    <p className="u-center">La réunion de cadrage est à planifier avant le :​</p>
                    <div className="u-mg-left-s u-bold">{dateService.formatDate(endedDate)}</div>
                </div>
            }

            {!isScopingMettingActivated && isScopingMeetingEnded &&
                <div className="u-flex u-flex-center-hz u-flex-center-vt u-mg-top-l u-mg-bottom-m u-bg-danger u-white u-pd-s u-fs-s">
                    <p className="u-center">Vous n’avez pas planifié votre réunion de cadrage dans les délais impartis. <br />Il n’est plus possible de poursuivre le parcours du programme OEPV​</p>
                </div>
            }

            <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-l">
                { hasPlannedAScopingMeeting && 
                    <button className="c-btn c-btn--primary u-pd-hz-xl u-mg-hz-m" onClick={goToScopingMeetingMonitoringPage}>
                        Suivre ma réunion de cadrage
                    </button>
                }
                { !hasPlannedAScopingMeeting && 
                    <button className="c-btn c-btn--primary u-pd-hz-xl u-mg-hz-m" onClick={handlePlanAScopingMeeting} disabled={!isScopingMettingActivated && (isPlanningAScopingMeeting || isScopingMeetingEnded)}>
                        Planifier ma réunion de cadrage 
                    </button>
                }
            </div>
        </div>
    )
}

export default ScopingMeetingPlanning